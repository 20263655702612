input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.background {
  width: 5em;
  height: 5em;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  filter: opacity(80%);
}

.rotate {
  transform: rotate(0deg);
  animation: rotate 0.3s linear;
}

@keyframes rotate {
  to {
    transform: rotate(-360deg);
  }
}
